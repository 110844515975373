import React, { useContext, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Heading, ThemeContext, Drop } from 'grommet';
import { FormUp } from 'grommet-icons';
import { m, LazyMotion } from 'framer-motion';

import ImgixImage from '../ImgixImage';
import SmartLink from '../SmartLink';
import { getProductPath } from '../../lib/product';
import findImage from '../../lib/findImage';
import useMobile from '../useMobile';

const AnimatedFormUp = m(FormUp);

const loadFeatures = () =>
  import('../../lib/framer/motionMaxFeatures.js').then((res) => res.default);

const ProductGroupProductSelector = ({
  products = [],
  product,
  transformProductName,
}) => {
  const theme = useContext(ThemeContext);
  const targetRef = useRef(null);
  const [open, setOpen] = useState(false);
  const isMobile = useMobile();

  return (
    <LazyMotion strict features={loadFeatures}>
      <Box fill>
        {open && (
          <Drop
            target={targetRef.current}
            align={{ bottom: 'top', left: 'left' }}
            style={{
              marginLeft: isMobile ? 0 : `-${theme.global.borderSize.small}`,
            }}
            plain
            onClickOutside={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!targetRef.current.contains(e.target)) {
                setOpen(false);
              }
            }}
            width={`${
              targetRef.current.getBoundingClientRect().width +
              parseInt(theme.global.borderSize.small) * 2
            }px`}
          >
            <Box
              fill
              border={[
                { side: 'vertical', size: 'small' },
                { side: 'top', size: 'small' },
              ]}
            >
              {products
                .filter((x) => x.id !== product.id)
                .map((x) => (
                  <SmartLink
                    size="small"
                    to={getProductPath(x)}
                    as={Button}
                    hoverIndicator={true}
                    key={x.id}
                    justify="start"
                    fill
                    style={{
                      padding: isMobile
                        ? theme.global.edgeSize.xxsmall
                        : theme.global.edgeSize.xsmall,
                    }}
                    icon={
                      <Box
                        alignSelf="center"
                        border={{ size: '1px', side: 'all' }}
                      >
                        <ImgixImage
                          src={
                            findImage(x.images || x.media, '_detail') ||
                            findImage(x.images || x.media, '_slide3')
                          }
                          srcSetOptions={{
                            w: 50,
                            h: 50,
                            fit: 'crop',
                            ar: '1:1',
                            dpr: 2,
                            q: 90,
                            auto: ['format', 'compress'],
                          }}
                        />
                      </Box>
                    }
                    label={
                      <Heading level={5}>
                        {transformProductName
                          ? transformProductName(x.title)
                          : x.title}
                      </Heading>
                    }
                  />
                ))}
            </Box>
          </Drop>
        )}
        <Box
          className="primary-item"
          ref={targetRef}
          justify={isMobile ? 'between' : 'center'}
          fill="horizontal"
          align="center"
          direction="row"
          gap="small"
          pad={{ left: 'xsmall', vertical: 'xsmall', right: 'small' }}
          focusIndicator={false}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Box border={{ size: '1px', side: 'all' }}>
            <ImgixImage
              src={
                findImage(product.images || product.media, '_detail') ||
                findImage(product.images || product.media, '_slide3')
              }
              srcSetOptions={{
                w: 50,
                h: 50,
                q: 90,
                fit: 'crop',
                dpr: 2,
                ar: '1:1',
                auto: ['format', 'compress'],
              }}
            />
          </Box>
          <Box flex={true}>
            <Heading style={{ whiteSpace: 'nowrap' }} level={5}>
              {product.title}
            </Heading>
          </Box>
          <Box flex={{ grow: 0, shrink: 1 }}>
            <AnimatedFormUp
              animate={{ rotate: open ? '180deg' : '0deg' }}
              color="black"
            />
          </Box>
        </Box>
      </Box>
    </LazyMotion>
  );
};

ProductGroupProductSelector.propTypes = {
  products: PropTypes.array,
  product: PropTypes.object,
  transformProductName: PropTypes.func,
};

export default memo(ProductGroupProductSelector);
