import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Stack, Box, ThemeContext } from 'grommet';
import { FormDown } from 'grommet-icons';

import InputNativeSelect from '../Inputs/InputNativeSelect';
import TextTitle from '../TextTitle';

const NativeQuantitySelector = ({ quantity, setQuantity, maxQty = 20 }) => {
  const options = new Array(maxQty).fill(undefined).map((x, i) => i + 1);
  const theme = useContext(ThemeContext);
  return (
    <Stack fill="vertical" guidingChild="last">
      <Box
        fill
        align="center"
        flex={true}
        justify="between"
        direction="row"
        pad={{ horizontal: 'medium', vertical: 'small' }}
      >
        <TextTitle size="small" as="label" htmlFor="quantity">
          QTY
        </TextTitle>
        <Box>
          <FormDown color="black" />
        </Box>
      </Box>
      <InputNativeSelect
        value={quantity}
        name="quantity"
        onChange={(e) => {
          setQuantity(e.target.value);
        }}
        style={{
          padding: `10px ${theme.global.edgeSize.large} 10px  ${theme.global.edgeSize.xlarge}`,
          fontSize: theme.text.small.size,
          background: 'transparent',
        }}
      >
        {options.map((x) => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </InputNativeSelect>
    </Stack>
  );
};

NativeQuantitySelector.propTypes = {
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
  maxQty: PropTypes.number,
};

export default NativeQuantitySelector;
