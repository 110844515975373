import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Heading } from 'grommet';
import { LazyMotion, m } from 'framer-motion';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import MarkdownContent from '../MarkdownContent';
import ButtonAddToCart from '../ButtonAddToCart';
import ImgixImage from '../ImgixImage';
import useMenuOffset from '../useMenuOffset';

import { findMemoVariant, isMemo, isSample } from '../../lib/product';
import findImage from '../../lib/findImage';
import useMobile from '../useMobile';
import { setIsScrolledPastSampleCartCustomScrollThreshold } from '../../state/ui/uiSlice';

const loadFeatures = () =>
  import('../../lib/framer/motionMaxFeatures.js').then((res) => res.default);

const MotionBox = m(Box);

const imageContainerVariants = {
  default: {
    width: '50vw',
  },
  expanded: {
    width: '100vw',
    transition: {
      duration: 0.5,
    },
  },
  hovered: {
    width: '53vw',
  },
  mobile: {
    width: '100%',
  },
};

const textContainerVariants = {
  default: {
    opacity: 1,
  },
  expanded: {
    opacity: 0,
  },
};

type Props = {
  title: string;
  description: string;
  variants: any[];
  images: any[];
  product: any;
  heroImageReveal?: boolean;
  addToCartLabel?: string;
  fullBleedImage?: boolean;
  onAddToCart?: (
    lineItems: {
      variantId: string;
      quantity: number;
    }[]
  ) => void;
};

const DefaultPdpHero = ({
  title,
  description,
  variants,
  images,
  product,
  heroImageReveal,
  addToCartLabel,
  fullBleedImage,
  onAddToCart,
}: Props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const shopButtonsRef = useRef(null);
  const sampleVariant = findMemoVariant(variants);
  const fullVariant = product.variants.find((x) => !isMemo(x) && !isSample(x));
  const [expanded, setExpanded] = useState(false);
  const image = findImage(images, '_hero') || images[0]?.originalSrc;
  const { navHeight } = useMenuOffset();
  const isScrolledPastShopButtons = useSelector(
    (state) => state.ui.isScrolledPastSampleCartCustomScrollThreshold
  );

  const subtitle = (product.metafields || []).find(
    (x) => x.namespace === 'descriptors' && x.key === 'subtitle'
  )?.value;

  useScrollPosition(
    ({ currPos }) => {
      if (!shopButtonsRef.current) {
        return;
      }
      if (Math.abs(currPos.y) >= shopButtonsRef.current.offsetTop) {
        if (!isScrolledPastShopButtons)
          dispatch(setIsScrolledPastSampleCartCustomScrollThreshold(true));
      } else {
        if (isScrolledPastShopButtons)
          dispatch(setIsScrolledPastSampleCartCustomScrollThreshold(false));
      }
    },
    [shopButtonsRef, isScrolledPastShopButtons]
  );

  return (
    <LazyMotion strict features={loadFeatures}>
      <Box height={{ min: '50vw' }}>
        <MotionBox
          direction={isMobile ? 'column-reverse' : 'row'}
          flex
          animate={isMobile ? 'mobile' : expanded ? 'expanded' : 'default'}
          style={{ position: 'relative' }}
        >
          <MotionBox flex pad="xlarge" variants={textContainerVariants} layout>
            <Box
              margin={
                isMobile
                  ? undefined
                  : { top: `${navHeight + (isMobile ? 0 : 30)}px` }
              }
              direction={isMobile ? 'column-reverse' : 'column'}
              gap="large"
              justify="end"
              fill
            >
              <Box gap="small">
                <Heading level={1}>{title}</Heading>

                {subtitle ? (
                  <Heading level={3}>{subtitle}</Heading>
                ) : (
                  <Heading level={3}>
                    {product.title.replace(title, '').replace('-', '')}
                  </Heading>
                )}

                <Box gap="medium">
                  <MarkdownContent>{description}</MarkdownContent>
                  <Box
                    direction="row-responsive"
                    gap="small"
                    ref={shopButtonsRef}
                  >
                    {sampleVariant && (
                      <ButtonAddToCart
                        primary
                        label="Get Sample"
                        variantId={sampleVariant?.shopifyId}
                      />
                    )}
                    <ButtonAddToCart
                      secondary={typeof sampleVariant !== 'undefined'}
                      primary={typeof sampleVariant === 'undefined'}
                      label={addToCartLabel || `Add`}
                      variantId={fullVariant?.shopifyId}
                      onAddToCart={onAddToCart}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </MotionBox>
          <MotionBox basis={isMobile ? '100%' : '50%'}>
            <MotionBox
              style={
                heroImageReveal
                  ? isMobile
                    ? { outline: 'none' }
                    : {
                        position: 'absolute',
                        right: 0,
                        height: '100%',
                        cursor: expanded ? 'e-resize' : 'w-resize',
                      }
                  : undefined
              }
              overflow="hidden"
              className="wallcoverings-hero-image-container"
              flex
              variants={imageContainerVariants}
              initial={
                isMobile
                  ? imageContainerVariants.mobile
                  : imageContainerVariants.default
              }
              onClick={
                heroImageReveal
                  ? () => {
                      isMobile ? undefined : setExpanded(!expanded);
                    }
                  : undefined
              }
              focusIndicator={false}
              align="end"
              height={isMobile ? { min: '60vh' } : undefined}
              pad={heroImageReveal || fullBleedImage ? 'none' : 'xlarge'}
            >
              <ImgixImage
                src={image}
                srcSetOptions={{ auto: ['format'], q: 75 }}
                srcSetRanges={{ minWidth: 200, maxWidth: 4000 }}
                sizes={
                  heroImageReveal
                    ? isMobile
                      ? '200vw'
                      : '100vw'
                    : isMobile
                    ? '100vw'
                    : '50vw'
                }
                fill={!heroImageReveal}
                fit={
                  fullBleedImage
                    ? 'cover'
                    : heroImageReveal
                    ? 'cover'
                    : 'contain'
                }
                style={
                  heroImageReveal
                    ? { width: '100vw', height: '100%' }
                    : undefined
                }
              />
            </MotionBox>
          </MotionBox>
        </MotionBox>
      </Box>
    </LazyMotion>
  );
};

DefaultPdpHero.propTypes = {
  handle: PropTypes.string,
  title: PropTypes.string,
  images: PropTypes.array,
  description: PropTypes.string,
  shortDescription: PropTypes.string,
  productType: PropTypes.string,
  variants: PropTypes.array.isRequired,
  dark: PropTypes.bool,
  backgroundColor: PropTypes.string,
  heroImage: PropTypes.object,
  pixelSwap: PropTypes.bool,
  type: PropTypes.string,
  alternateProducts: PropTypes.array,
  patternTitle: PropTypes.string,
  product: PropTypes.object,
  onSetShopButtonsVisibility: PropTypes.func,
  heroImageReveal: PropTypes.bool,
  addToCartLabel: PropTypes.string,
  fullBleedImage: PropTypes.boolean,
};

export default memo(DefaultPdpHero);
