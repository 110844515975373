import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { Box } from 'grommet';
import styled from 'styled-components';
import { AnimatePresence, m } from 'framer-motion';

import ButtonAddToCart from '../ButtonAddToCart';
import ProductGroupProductSelector from './ProductGroupProductSelector';
import NativeQuantitySelector from './NativeQuantitySelector';

import useBuybarUi from './useBuybarUi';
import useMobile from '../useMobile';
import { isMemo, isSample } from '../../lib/product';
import { track } from '../../lib/analytics';
import { SAMPLE_ADDED } from '../../lib/analytics/segmentActions';

const ResponsiveA2CButton = styled(ButtonAddToCart)`
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    padding: ${(p) => parseInt(p.theme.global.edgeSize.small) * 1.5}px;
  }
`;

const MotionBox = m(Box);

type Props = {
  sample: any;
  products: any[];
  productGroup: any;
  product: any;
  buybarVisible: boolean;
  maxQty?: number;
  addToCartLabel: string;
  showQuantitySelector: boolean;
  onAddToCart?: (
    lineItems: {
      variantId: string;
      quantity: number;
    }[]
  ) => void;
};

const PoliteProductBuyOptions = ({
  sample,
  products,
  productGroup,
  product,
  buybarVisible,
  maxQty = 30,
  addToCartLabel,
  showQuantitySelector = true,
  onAddToCart,
}: Props) => {
  const isMobile = useMobile();
  const location = useLocation();
  const [quantity, setQuantity] = useState(1);
  const { buybarRef } = useBuybarUi();

  const fullVariant = product.variants.find((x) => !isMemo(x) && !isSample(x));

  const handleSampleAdded = useCallback(() => {
    track(SAMPLE_ADDED, {
      product: productGroup ? productGroup.title : product.title,
      pdp: true,
      cdp: false,
      location: location.pathname,
    });
  }, [track, productGroup, product, location]);

  return (
    <Box gap="medium" fill={isMobile ? 'horizontal' : false}>
      <AnimatePresence>
        {buybarVisible && (
          <MotionBox
            direction="row-responsive"
            gap={isMobile ? 0 : !buybarVisible ? 'medium' : 'none'}
            ref={buybarRef}
            initial={{ y: 200 }}
            animate={{ y: 0 }}
            exit={{ y: 200 }}
            transition={{
              type: 'tween',
            }}
          >
            <Box>
              {products && products.length > 1 && (
                <Box
                  direction="row"
                  fill="vertical"
                  border={{ side: 'all', size: 'small' }}
                  style={
                    isMobile
                      ? { borderBottom: 'none' }
                      : { borderRight: 'none' }
                  }
                  background="white"
                  gap="small"
                  align="center"
                  justify={isMobile ? 'start' : 'center'}
                >
                  <ProductGroupProductSelector
                    product={product}
                    products={products}
                    transformProductName={(name) =>
                      name.replace(productGroup.title, '').replace('-', '')
                    }
                  />
                </Box>
              )}
            </Box>

            <Box
              background="white"
              direction="row-reverse"
              border={
                showQuantitySelector
                  ? { side: 'left', size: 'small' }
                  : undefined
              }
            >
              {isMobile && !showQuantitySelector && (
                <Box
                  direction="row"
                  fill="horizontal"
                  align-items="stretch"
                  gap={isMobile ? 0 : !buybarVisible ? 'medium' : 'none'}
                >
                  {sample && (
                    <Box
                      width={isMobile ? 'auto' : '220px'}
                      flex={isMobile ? true : false}
                      height={isMobile ? '100%' : '61px'}
                    >
                      <ResponsiveA2CButton
                        quantity={1}
                        fill
                        variantId={sample?.shopifyId}
                        size="small"
                        label="Get Sample"
                        primary
                        onAdded={handleSampleAdded}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {showQuantitySelector && (
                <Box
                  border={
                    isMobile
                      ? [
                          { side: 'top', size: 'small' },
                          { side: 'bottom', size: 'small' },
                        ]
                      : { side: 'top', size: 'small' }
                  }
                  flex={true}
                >
                  <NativeQuantitySelector
                    quantity={quantity}
                    setQuantity={setQuantity}
                    maxQty={maxQty}
                  />
                </Box>
              )}
              <ButtonAddToCart
                secondary={typeof sample !== 'undefined'}
                primary={typeof sample === 'undefined'}
                variantId={fullVariant?.shopifyId}
                quantity={quantity}
                label={addToCartLabel}
                size="small"
                useIcon={true}
                onAddToCart={onAddToCart}
              />
            </Box>
            {!isMobile && (
              <Box
                direction="row"
                gap={isMobile ? 0 : !buybarVisible ? 'medium' : 'none'}
              >
                {sample && (
                  <Box
                    width={isMobile ? 'auto' : '220px'}
                    flex={isMobile ? true : false}
                  >
                    <ResponsiveA2CButton
                      quantity={1}
                      fill
                      variantId={sample?.shopifyId}
                      size="small"
                      label="Get Sample"
                      primary
                      onAdded={handleSampleAdded}
                    />
                  </Box>
                )}
              </Box>
            )}
          </MotionBox>
        )}
      </AnimatePresence>
    </Box>
  );
};

PoliteProductBuyOptions.propTypes = {
  sample: PropTypes.object,
  products: PropTypes.array,
  product: PropTypes.object,
  productGroup: PropTypes.object,
  productType: PropTypes.string,
  dark: PropTypes.bool,
  imageUrl: PropTypes.string,
  buybarVisible: PropTypes.bool,
  maxQty: PropTypes.number,
  addToCartLabel: PropTypes.string,
};

export default memo(PoliteProductBuyOptions);
