import React, { memo, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Stack } from 'grommet';

import { useStoryblokState } from '../../lib/storyblok';
import { get, head } from '../../lib/nodash';
import {
  findDefaultTraditionalVariant,
  findMemoVariant,
} from '../../lib/product';
import findImage from '../../lib/findImage';
import usePageBodyComponents from '../DynamicPage/usePageBodyComponents';
import useWallcoveringsCollectionData from './useWallcoveringsCollectionData';
import { setSampleCartCustomScrollThreshold } from '../../state/ui/uiSlice';
import useMobile from '../useMobile';

import DefaultPdpHero from '../Product/DefaultPdpHero';
import PoliteProductBuyOptions from '../Product/PoliteProductBuyOptions';
import ProductBreadcrumbHero from '../Product/ProductBreadcrumbHero';
import Buybar from '../Buybar';
import { BACKDROP_WALLCOVERINGS_DESIGNERS } from '../../lib/constants';

const PageProductWallcovering = ({
  product,
  wallcoveringsCollection,
  relatedProducts,
  relatedCollections,
  relatedPaintCollections,
  relatedColorProducts,
}) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const isScrolledPastShopButtons = useSelector(
    (state) => state.ui.isScrolledPastSampleCartCustomScrollThreshold
  );
  const { backdropPdp, otherPdp } = useStaticQuery(graphql`
    query WallcoveringsPdpPage {
      backdropPdp: storyblokEntry(slug: { eq: "wallcoverings-pdp" }) {
        id
        name
        full_slug
        content
        field_component
        internalId
      }
      otherPdp: storyblokEntry(slug: { eq: "wallcoverings-pdp-designer" }) {
        id
        name
        full_slug
        content
        field_component
        internalId
      }
    }
  `);
  const designerName = product.metafields.find(
    ({ key, namespace }) => namespace === 'designer' && key === 'name'
  )?.value;
  const isBackdropWallcovering = BACKDROP_WALLCOVERINGS_DESIGNERS.includes(
    designerName.toLowerCase()
  );
  const story = isBackdropWallcovering ? backdropPdp : otherPdp;
  const pdpTemplate = useStoryblokState(story);
  const sampleCartOpen = useSelector((state) => state.cart.sampleCartOpen);

  const {
    title,
    hexValue,
    isDark,
    description,
    products,
    variants,
    tags,
    shortDescription,
    heroImage,
  } = useWallcoveringsCollectionData(wallcoveringsCollection, product);

  const collectionImage = findImage(product.media, '_detail');

  const sampleVariant = findMemoVariant(variants);
  const borderSettings = { side: 'bottom', size: 'small', color: 'black' };

  const pageComponents = usePageBodyComponents(pdpTemplate, {
    collection: wallcoveringsCollection,
    wallcoveringsPattern: wallcoveringsCollection,
    product,
    relatedProducts,
    relatedCollections,
    relatedPaintCollections,
    relatedColorProducts,
  });

  useEffect(() => {
    dispatch(setSampleCartCustomScrollThreshold(true));
    return () => dispatch(setSampleCartCustomScrollThreshold(false));
  }, []);

  const baseCollections = product.collections.filter(
    (x) => x.collectionType?.value === 'Wallcoverings Collection'
  );
  const baseCollection =
    baseCollections.find((x) => x.handle === 'wallcoverings') ||
    baseCollections[0];

  const traditionalVariant =
    findDefaultTraditionalVariant(variants) || variants[0];

  const selectorOptions = {
    collectionType: 'wallcoverings',
    wallcoveringsCollectionId:
      wallcoveringsCollection?.shopifyId ?? wallcoveringsCollection?.id,
    variants,
    variantId: traditionalVariant?.shopifyId ?? traditionalVariant?.id,
    productType: get('productType', head(products)),
    altProducts: products,
    wallcoveringsCollection: wallcoveringsCollection,
    product,
  };

  return (
    <>
      <Stack anchor="bottom">
        <Box>
          <ProductBreadcrumbHero
            product={{
              title: title,
              productType: get('productType', product),
              handle: get('handle', product),
              tags: tags,
            }}
            base={{
              title:
                baseCollection?.handle === 'wallcoverings'
                  ? 'Wallcoverings'
                  : baseCollection?.title || 'Wallcoverings',
              slug: `collections/${baseCollection?.handle || 'wallcoverings'}/`,
            }}
          />
          <DefaultPdpHero
            variants={variants}
            title={title}
            backgroundColor={hexValue}
            description={description}
            shortDescription={shortDescription}
            border={borderSettings}
            dark={isDark}
            heroImage={heroImage}
            type={'Wallcoverings'}
            productType={get('productType', product)}
            images={product.media}
            pixelSwap={false}
            alternateProducts={wallcoveringsCollection?.products || []}
            patternTitle={wallcoveringsCollection?.title}
            product={product}
            addToCartLabel="Add Wallcovering"
            heroImageReveal={isBackdropWallcovering}
            fullBleedImage={!isBackdropWallcovering}
          />
          {pageComponents}
        </Box>
        <Buybar willDetach={false} position="bottom-right">
          <PoliteProductBuyOptions
            sample={sampleVariant}
            products={products}
            productGroup={wallcoveringsCollection}
            product={product}
            imageUrl={collectionImage}
            addToCartLabel="Add Wallcovering"
            showQuantitySelector={false}
            buybarVisible={
              isMobile
                ? isScrolledPastShopButtons && !sampleCartOpen
                : isScrolledPastShopButtons
            }
          />
        </Buybar>
      </Stack>
    </>
  );
};

PageProductWallcovering.propTypes = {
  product: PropTypes.object,
  collection: PropTypes.object,
  wallcoveringsCollection: PropTypes.object,
  relatedCollections: PropTypes.array,
  relatedProducts: PropTypes.array,
  relatedPaintCollections: PropTypes.array,
  relatedColorProducts: PropTypes.array,
  location: PropTypes.object,
};

export default memo(PageProductWallcovering);
